//===================================================
//	font setting
//===================================================
//font-size
$f-sp:14; //スマホ用フォントサイズ
$f-tb:15; //タブレット用フォントサイズ
$f-pc:16; //PC用フォントサイズ


//font-family
$font-set:(
	font01:"'Noto Sans JP'", //メインフォントファミリー
	font02:"'Oswald'", //英字フォントファミリー
	font03:"'Noto Serif JP'", //その他ファミリー
);


/* webfont 変数　*/
$icons: (
  arrow-up: "\e907",
  arrow-right: "\e901",
  arrow-down: "\e906",  
  arrow-left: "\e902",
  top_arrow: "\e316",
  right_arrow: "\e315",
  down_arrow: "\e313",  
  left_arrow: "\e314",
  home: "\e90c",
  phone: "\e909",
  mail: "\e911",
  link: "\e900",
  pdf: "\e903",
  tag: "\e904",
  search: "\e905",
  search-plus: "\e908",
  facebook: "\e90a",
  facebook2:"\e912",
  twitter: "\e90d",
  instagram: "\e90b",
  line: "\e90e",
  youtube: "\e910"
) !default;

@mixin icon($icon: false, $position:before) {
  @if $position == both {
    $position: ":before,:after";
  }
  &:#{$position} {
    @if $icon {
      content: "#{map-get($icons, $icon)}";
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  @content;
}