//アスペクト比
@mixin aspect-img($horizontal:3,$vertical:2) {
	position: relative;
	overflow:hidden;
	width: 100%;
	z-index: z-index(module,part04);
	&::before {
		content: "";
		display: block;
		padding-top: floor-decimal(($vertical/$horizontal)*100%);
		position: relative;
	}
	& > div,
	& > figure,
	& > a,
	& > picture {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}

@mixin light-box {
	display:block;
	overflow:hidden;
	position:relative;
	@include icon(search-plus,after);
	&::after {
		position:absolute;
		display:block;
		@include dec-none;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		font-size:.5rem;
		opacity:0;
		@include transition;
		color:$white;
		z-index: z-index(module03);
	}
	& img {
		@include transition;
	}
	&:hover {
		img {
			transform: scale(1.1, 1.1);
			filter: brightness(50%);
		}
		&::after {
			font-size:3rem;
			opacity:1;
		}
	}
}

@mixin img-scale {
	& figure {
		overflow:hidden;
	}
	& img {
		@include transition;
	}
	&:hover {
		img {
		transform: scale(1.1, 1.1);
		}
	}
}

/* ofi */
@mixin ofi-contain{
		width: 100%;
		height: 100%;
		object-fit: contain;
		font-family: 'object-fit: contain;'
}

@mixin ofi-cover{
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover;'
}