@charset "UTF-8";
/* webfont 変数　*/
/* common-title
-----------------------------------------------------*/
/* ofi */
@media (min-width: 1920px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1536px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1024px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1919px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1535px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1023px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1024px) and (max-width: 1535px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1536px) and (max-width: 1919px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/*====================================================================

topanimation.css

=====================================================================*/
.main-view {
  position: relative;
}

.main-view__filter {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  opacity: 0;
}

.main-view .l-cont {
  z-index: 3;
  position: relative;
  width: 100%;
  height: 100%;
}

.main-view__bg__img__inner {
  height: 100%;
}

.main-view__bg__img__inner img {
  transform: scale(1.1, 1.1);
}

.main-view__bg__img__inner.active img {
  animation: bg-scale 4s cubic-bezier(0.09, 0.35, 0.66, 0.99) forwards;
}

@keyframes bg-scale {
  0% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.catch-01, .catch-02, .catch-03 {
  position: relative;
  display: block;
  padding-right: 0.05em;
  overflow: hidden;
}

.catch-01__letter, .catch-02__letter, .catch-03__letter {
  display: inline-block;
}
