@charset "utf-8";
@import "base";
/*====================================================================

topanimation.css

=====================================================================*/
.main-view {
	position: relative;
	&__filter {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: $white;
		z-index: z-index(module, part03);
		opacity: 0;
	}
	& .l-cont {
		z-index: z-index(module, part02);
		position: relative;
		width: 100%;
		height: 100%;
	}
}

// 背景画像スケール
.main-view__bg__img {
	&__inner {
		height: 100%;
		img {
			transform: scale(1.1, 1.1);
		}
		&.active {
			img {
				animation: bg-scale 4s cubic-bezier(0.09, 0.35, 0.66, 0.99) forwards;
			}
		}
	}
}
@keyframes bg-scale {
	0% {
		transform: scale(1.1, 1.1);
	}
	100% {
		transform: scale(1, 1);
	}
}

.catch-01,.catch-02,.catch-03 {
	position: relative;
	display: block;
	padding-right: 0.05em;
	overflow: hidden;
	&__letter {
		display: inline-block;
	}
}